<!-- 对比学习 -->
<template>
  <div class="divTop">
    <el-scrollbar style="height:100%">
      <el-card style="height:100%" class="cardTop">
        <el-backtop target=".el-scrollbar__wrap">
          <i class="el-icon-top"></i>
        </el-backtop>
        <div class="case_div">
          <el-button
            class="el-icon-back"
            style="margin:0px 10px 0px 0 ;display:inline-block"
            @click="go_return"
          >返回</el-button>
          <span class="case_sn">
            变式名：
            <el-select
              v-model="model"
              placeholder
              size="mini"
              style="width:350px"
              :disabled="$route.query.show==1"
            >
              <el-option
                v-for="item in options"
                :key="item.caseId"
                :label="item.stemName"
                :value="item.caseId"
              ></el-option>
            </el-select>
          </span>
          <span style="margin-left:30px" class="case_rate">
            难度：
            <el-rate
              v-model="subjectData.difficulty"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </span>
        </div>
        <el-collapse v-model="activeNames">
          <el-collapse-item title="题目内容" name="1">
            <div v-html="descriptionsValue" class="des"></div>
          </el-collapse-item>
          <el-collapse-item title="题目描述" name="2" class="collapse_ul">
            <ul>
              <li v-for="(item, i) in descriptions" :key="i" v-if="item.descriptionType!=='6'">
                {{ descriptionType[item.descriptionType] }}
                <div :class="item.descriptionType == 6 ? 'des_p' : ''">
                  <b v-html="item.description" class="des"></b>
                </div>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
        <div class="div_left">
          <div class="div_questions" v-for="(item,index) in questions" :key="index">
            <span class="questions_span">
              {{item.sn}}
              <i class="qtype">[{{questionsType[item.qtype]}}]</i>
            </span>
            <div style="padding:0 10px">
              <div class="title_div">
                <span class="title_span" v-html="item.stemContent"></span>
              </div>
              <span v-for="(itm,indx) in item.choices" :key="indx">
                <span
                  :class="itm.isAnswer == 1?'answer_span answer_span_ok':'answer_span'"
                  @click="click_answer(itm)"
                >
                  <span class="sn_span">{{itm.sn}}</span>
                  <span v-html="itm.choiceContent" class="answer_span_son"></span>
                </span>
                <el-collapse-transition>
                  <div v-show="itm.show" class="reason" v-html="itm.cause" v-if="itm.cause"></div>
                </el-collapse-transition>
              </span>
            </div>
          </div>
        </div>
        <div class="div_right">
          <div class="div_questions" v-for="(item,index) in questions" :key="index">
            <span class="questions_span">
              {{item.sn}}
              <i class="qtype">[{{questionsType[item.qtype]}}]</i>
              <span
                :class="item.userAnswerResult == 0 ? 'green' : 'red'"
              >{{ userAnswerResultType[item.userAnswerResult] }}</span>
            </span>
            <div style="padding:0 10px">
              <div class="title_div">
                <span class="title_span" v-html="item.stemContent"></span>
              </div>
              <span v-for="(itm,indx) in item.choices" :key="indx" style="position: relative;">
                <span
                  :class="itm.state !== null?'answer_span answer_span_ok':'answer_span'"
                  @click="click_answer(itm)"
                >
                  <span class="first_answer">
                    <span
                      class="first_answer_span"
                      v-if="itm.count == '1,'||itm.count == '1,2,'||itm.count == '2,1,'"
                    >①</span>
                    <span
                      class="second_answer_span"
                      v-if="itm.count == '2,'||itm.count == '1,2,' ||itm.count == '2,1,'"
                    >②</span>
                  </span>
                  <span class="sn_span">{{itm.sn}}</span>
                  <span v-html="itm.choiceContent" class="answer_span_son"></span>
                </span>
                <el-collapse-transition>
                  <div class="reason" v-show="itm.show" v-if="itm.cause" v-html="itm.cause"></div>
                </el-collapse-transition>
              </span>
            </div>
          </div>
        </div>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
import { activeDetails } from "../../api/api";
export default {
  data () {
    return {
      activeNames: [''],//活动名字
      subjectData: {},//题目详情(这个页面只用了难度字段)
      descriptionsValue: '',//题目内容
      descriptionType: this.parameter.descriptionType(),
      questionsType: {//题目类型
        2: "单选题",
        3: "多选题",
      },
      userAnswerResultType: {//答案是否正确
        0: "您的答案正确!",
        1: "您的答案错误!",
      },
      descriptions: [],//题目描述
      questions: [],//答案
      options: [],//变式集合
      model: '',//选中的变式
    }
  },

  components: {},
  mounted () {
    this.loadDetail()
    let userId = Number(this.utils.userId())
    _hmt.push(['_trackEvent', '对比式学习', '查看', '查看答案', userId]);
  },
  watch: {
    // 监听选中的变式是否发生改变
    model (val, old) {
      if (val !== old) {
        this.show_subject()
      }
    }
  },
  methods: {
    // 加载活动详情
    loadDetail () {
      activeDetails({ id: this.$route.query.id }).then(res => {
        if (res.code == 0) {
          this.options = res.data.stems
          if (this.$route.query.show) {
            let caseObj = this.options.find(item => { return item.caseId == this.$route.query.caseId })
            this.model = caseObj.caseId
          } else if (!this.$route.query.show && this.$route.query.caseId) {
            let caseObj = this.options.find(item => { return item.caseId == this.$route.query.caseId })
            this.model = caseObj.caseId
          } else {
            this.model = this.options[0].caseId
          }
        }
      })
    },
    // 返回
    go_return () {
      this.$router.go(-1)
    },
    // 点击选项显示错误原因
    click_answer (val) {
      this.questions.map(item => {
        item.choices.map(itm => {
          if (itm.id == val.id) {
            itm.show = !itm.show
            this.$forceUpdate();//强制刷新vue
          }
        })
      })
    },
    // 获取题目详情
    show_subject () {
      let subject_params = this.options.find(item => { return item.caseId == this.model })
      this.subject_dialog = true
      let param = {
        'caseId': subject_params.caseId,
        'activeId': subject_params.activeId,
        'caseType': 0,
        'isComplete': 1,
        'taskId': subject_params.id
      }
      this.$api.page.getCase(param).then(res => {
        if (res.data.code == 0) {
          let a = res.data.data.descriptions.find(item => { return item.descriptionType == '6' })
          if (a) {
            this.descriptionsValue = a.description
          }
          this.subjectData = res.data.data
          this.descriptions = res.data.data.descriptions
          this.questions = res.data.data.questions
          this.questions.sort((a, b) => {
            return a.displaySN - b.displaySN;
          });
          for (let i = 0; i < this.questions.length; i++) {
            this.questions[i].choices = this.questions[i].choices.sort(
              (a, b) => {
                return a.sn + "" > b.sn + "" ? 1 : -1;
              }
            );
            this.questions[i].choices.forEach(x => {
              x.show = false;
            });
            this.questions[i].answer = this.questions[i].choices.map((a, i) => {
              let str = null;
              a.isAnswer == 1 ? (str = a.sn) : (str = null);
              return str;
            });
            this.questions[i].answer = this.questions[i].answer.filter(
              (item) => {
                return item !== null;
              }
            );
          }
        }
      })
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
@import '../../style/comparative/index.less';
</style>
